import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ClientReview from '../Component/Review/ClientReview';
import './Home.css';
import ShakeOnScroll from '../Component/ShakeOnScroll';
import Button from '../Component/Button';
import { scheduledCall } from '../../service/appService';
import Notification from '../Component/Notification';

function Home() {
  const reviews = [
    {
      id: 1,
      authorName: 'Akande Oluwatunmise',
      authorUrl: 'https://plus.google.com/',
      profilePhotoUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
      rating: 5,
      text: 'Dan was hyper-responsive, had the knowledge of having done something similar with me before, and was incredibly efficient. This is why we work with Oval Professional services and not an ...',
    },
    {
      id: 2,
      authorName: 'Jane Smith',
      authorUrl: 'https://twitter.com/janesmith',
      profilePhotoUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
      rating: 4,
      text: 'My experience was great because support is offered at the earliest convenience. Have worked with most of the staff and they are not only very efficient but willing to look at all details...',
    },
    {
      id: 3,
      authorName: 'Alice Johnson',
      authorUrl: 'https://facebook.com/alicejohnson',
      profilePhotoUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
      rating: 4,
      relativeTimeDescription: '3 weeks ago',
      text: 'Dan was hyper-responsive, had the knowledge of having done something similar with me before, and was incredibly efficient. This is why we work with Oval Professional services and not an ...',
    },
  ];

  const [callSchedule, setCallSchedule] = useState({
    name: '',
    email: '',
    phone_number: '',
  });
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  const handleCallSchedule = (e) => {
    const {
      name,
      value,
      type,
      checked,
    } = e.target;
    if (type === 'checkbox') {
      setTermsAccepted(checked);
    } else {
      setCallSchedule((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCallScheduleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      alert('You must accept the terms and conditions.');
      return;
    }
    setLoading(true);
    try {
      const newCallSchedule = await dispatch(scheduledCall(callSchedule)).unwrap();
      if (newCallSchedule) {
        setCallSchedule({
          name: '',
          email: '',
          phone_number: '',
        });
        setNotification({ message: newCallSchedule.message, type: 'success' });
        setTimeout(() => {
          setNotification({ message: '', type: 'success' });
          navigate('/');
        }, 7000);
      } else {
        setNotification({ message: newCallSchedule.error.message, type: 'error' });
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setNotification({ message: error.message, type: 'error' });
      setTimeout(() => {
        setNotification({ message: '', type: '' });
      }, 7000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="flex-col justify-center mt-0 w-full">
      <section className="">
        <Notification notification={notification} />
        <div className=" grid md:grid-flow-col lg:grid-flow-col bg-center bg-cover p-2 lg:p-6 w-full  bg-no-repeat bg-[url('https://cdn.pixabay.com/photo/2017/08/11/10/54/industry-2630319_640.jpg')]  bg-blend-multiply filter dark:none">
          <div className="text-white items-center justify-center flex-1 mt-3 w-full">
            <p className="p-8 font-bold text-xl text-center lg:p-24">
              Oval Professional Services:
              {' '}
              <span className="text-orange-400">Shaping</span>
              {' '}
              the
              {' '}
              <span className="text-orange-400">Future</span>
              {' '}
              with
              {' '}
              One
              {' '}
              <span className="text-orange-400">Solution</span>
              {' '}
              at
              {' '}
              a
              {' '}
              <span className="text-orange-400">Time</span>
              .
            </p>
            <div className="text-2xl-custom text-white leading-2-custom font-extrabold p-3 lg:text-6xl ">
              <h1>
                Premium IT Consulting That Goes Far Beyond Basic Tech Support
              </h1>
            </div>
          </div>
          <div className="flex flex-col m-0 w-full items-center justify-center text-center border rounded-lg p-4 gap-2 bg-gray-50 dark:bg-gray-800">
            <div className="font-semibold">
              <h2>How Can We Help?</h2>
            </div>
            <hr className="w-full" />
            <div className="p-3 ">
              <p>It&#39;s our job to help your bussiness work faster and morre profitable by taking all routine IT task off your plate</p>
            </div>
            <div className="sch-a-c">
              <p>Schedule a 15-minutes call to see if we are good fit to help your organisation</p>
            </div>
            <form onSubmit={handleCallScheduleSubmit} className=" flex flex-col gap-3 p-1 ">
              <span className="text-start">Enter your name, email and phone number to get started</span>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={callSchedule.name}
                onChange={handleCallSchedule}
                className="border rounded-lg border-gray-400 w-full p-3"
                required
              />
              <input
                type="text"
                name="email"
                placeholder="Enter your Email"
                value={callSchedule.email}
                onChange={handleCallSchedule}
                className="border rounded-lg border-gray-400 w-full p-3"
                required
              />
              <input
                type="text"
                name="phone_number"
                placeholder="Enter your Phone Number"
                value={callSchedule.phone_number}
                onChange={handleCallSchedule}
                className="border rounded-lg border-gray-400 w-full p-3"
                required
              />
              <div className="w-full flex items-center">
                <input
                  type="checkbox"
                  name="terms"
                  checked={termsAccepted}
                  onChange={handleCallSchedule}
                />
                <p className="ml-2">
                  I accept the terms and conditions
                </p>
              </div>
              <div className="btn">
                <Button
                  type="submit"
                  loading={loading}
                  text="SCHEDULE NOW"
                  ariaLabel="Submit button"
                  className="justify-start  bg-blue-500"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="flex mt-12 p-3">
        <div className="we grid md:grid-cols-2 md:order-2 gap-6 lg:grid-col-2">
          <div className="h-96 rounded-xl bg-center object-cover bg-no-repeat bg-[url('https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_640.jpg')] bg-blend-multiply" />
          <div className="we-txt flex flex-col md:order-1 gap-1 w-full md:w-full">
            <div className="we-in text-justify">
              <p className="text-17 text-wrap p-6">
                <strong><span className="text-orange-400 mr-2 text-justify">Oval Professional Services</span></strong>
                is a full service IT Company that empowers businesses with innovative IT solutions that drive Growth, Efficiency, Success.
              </p>
              <p className="text-17 text-wrap p-6">
                Our team is comprised of seasoned consultants with extensive experience across IT, healthcare, and the energy sectors. We&apos;ve consistently delivered innovative solutions that drive efficiency and foster growth. Our commitment to excellence ensures that our clients receive tailored strategies and seamless integration, empowering them to navigate the complexities of their industries with confidence.
              </p>
            </div>
            <span className="p-2 mt-2">
              <Link to="/schedule-your-appointment" className="bg-slate-500 text-white text-sm font-bold rounded p-3">
                LET&#39;S TALK TECH
              </Link>
            </span>
          </div>
        </div>
      </section>
      <section className="flex flex-col p-3 mt-8">
        <div className="h-ca p-1 border rounded-lg">
          <h3 className="review text-center font-bold text-2xl text-slate-500 mt-8">See What Our Clients Are Saying</h3>
          <div className="grid gap-5 md:grid-cols-3 p-5 lg:p-14 lg:grid-cols-3">
            {reviews.map((review) => (
              <div key={review.id} className="">
                <ClientReview review={review} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center mt-10">
          <div className="text-2xl font-extrabold text-slate-500">
            <h2>Our Pillars</h2>
          </div>
          <div className="pi-ca w-full grid gap-5 md:grid-cols-3 lg:grid-flow-col p-5 lg:px-24 mt-6">
            <div className="gr-ca relative rounded-lg max-w-xl mx-auto h-full lg:gap-14 object-cover bg-gray-50 dark:bg-gray-800 border shadow-xl opacity-70">
              <img src="https://cdn.pixabay.com/photo/2024/02/20/09/56/network-connections-8585083_640.jpg" className="h-32 w-full object-cover rounded-lg" alt="growth" />
              <div className=" inset-0 flex flex-col items-center justify-center p-2 lg:p-5 gap-8">
                <h3 className="text-slate-900 text-3xl font-bold">Growth</h3>
                <p className="flex w-full leading-5 text-lg font-normal justify-center items-center text-center text-slate-900">Our technology solutions enable businesses to adapt to market changes swiftly, harness data-driven insights, and exploring new avenues for expansion</p>
              </div>
            </div>
            <div className="gr-ca relative rounded-lg max-w-xl mx-auto h-full object-cover bg-gray-50 dark:bg-gray-800 border shadow-xl opacity-70">
              <img src="https://cdn.pixabay.com/photo/2017/08/13/05/08/deadline-2636259_640.jpg" className="h-32 w-full object-cover rounded-lg" alt="efficiency" />
              <div className="inset-0 flex flex-col items-center justify-center p-2 lg:p-5 gap-8">
                <h3 className="text-slate-900 text-3xl font-bold">Efficency</h3>
                <p className="flex w-full leading-5 text-lg font-normal justify-center items-center text-center text-slate-900">Our solutions minimize downtime and enhance productivity, allowing teams to focus on what truly matters—delivering value to their customers and meeting deadlines effectively.</p>
              </div>
            </div>
            <div className="gr-ca relative rounded-lg max-w-xl mx-auto h-full object-cover bg-gray-50 dark:bg-gray-800 border shadow-xl opacity-70">
              <img src="https://cdn.pixabay.com/photo/2021/03/19/12/26/man-6107447_640.png" className="h-32 w-full rounded-lg" alt="growth" />
              <div className="inset-0 flex flex-col items-center justify-center p-2 lg:p-5 gap-8">
                <h3 className="text-slate-900 text-3xl font-bold">Success</h3>
                <p className="flex w-full leading-5 text-lg font-normal justify-center items-center text-center text-slate-900">We believe that by equipping businesses with the right tools and technologies, we can help them achieve their goals and realize their visions on their journey, ensuring they thrive in an ever-evolving digital world.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black text-white mt-24">
        <article className="p-5 text-justify lg:p-12 lg:flex lg:flex-row lg:gap-4 text-base leading-none">
          <p className=" flex lg:w-full text-base lg:px-8">
            With the proper level of IT services and support, there&#39;s literally nothing your business can&#39;t accomplish. That&#39;s exactly what we want for you and, at the end of the day, that&#39;s what our IT consulting and managed services are all about.
          </p>
          <div className="flex lg:w-full lg:px-8">
            <p className="text-base font-semibold leading-tight mt-6 lg:mt-0 break-words w-full">
              Getting started is easy. Give us a call at
              <strong className="break-before-auto"> (240) 468-7794 </strong>
              or complete the form, and we will get in touch with you to set up an introductory phone call.
            </p>
          </div>
        </article>
        <section className="mt-4 p-4 lg:p-12 grid w-full gap-3 md:grid-flow-col">
          <div className="w-full">
            <h4 className="flex text-2xl font-bold text-orange-500 next">
              What Happens Next?
            </h4>
            <ShakeOnScroll />
          </div>
          <div className="relative w-full h-80 flex">
            <img src="https://media.istockphoto.com/id/1745502635/photo/hand-of-an-asian-woman-operating-a-computer-at-a-cafe.jpg?s=612x612&w=0&k=20&c=Occ06NOUJETtxYCYuJGhnvPlBwDUwArpek1j_vFZArE=" alt="" className=" rounded-lg object-cover w-full opacity-60" />
            <div className="sch-li absolute inset-0 flex items-center justify-center">
              <span className="w-full justify-center items-center p-5 flex">
                <Link to="/schedule-your-appointment" className="bg-slate-500 text-white text-sm font-bold rounded p-5">
                  LET&#39;S TALK TECH
                </Link>
              </span>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}

export default Home;
