import React from 'react';
import PropTypes from 'prop-types';
import StarRating from './StarRating';

const ClientReview = ({ review }) => {
  const {
    id,
    authorName,
    authorUrl,
    profilePhotoUrl,
    rating,
    text,
  } = review;

  return (
    <div className="flex flex-col h-full justify-evenly border-solid border-spacing-0 border-t-16 border-slate-500 rounded-2xl bg-white shadow-2xl p-3 lg:p-8" id={id}>
      <div className="review__card__content p-2">
        <div className="">
          <StarRating rating={rating} />
        </div>
        <p className="review__card__text text-justify mt-2">{text}</p>
      </div>
      <div className="flex flex-row items-center justify-start p-2 gap-8">
        <img
          src={profilePhotoUrl}
          alt={`${authorName}'s profile`}
          className="w-12 h-12 border rounded-3xl bg-slate-500"
        />
        <div className="">
          <a
            href={authorUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="review__card__author-name"
          >
            {authorName}
          </a>
        </div>
      </div>
    </div>
  );
};

// Prop validation for the ReviewCard component
ClientReview.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number.isRequired, // Added id
    authorName: PropTypes.string.isRequired,
    authorUrl: PropTypes.string.isRequired,
    profilePhotoUrl: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default ClientReview;
