import React, { useEffect, useRef, useState } from 'react';

const ShakeOnScroll = () => {
  const divRefs = [useRef(null), useRef(null), useRef(null)];
  const [isVisible, setIsVisible] = useState([false, false, false]);

  useEffect(() => {
    const observers = divRefs.map((ref, index) => new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = true; // Set the current div as visible
            return newVisibility;
          });
          observers[index].disconnect(); // Stop observing once it's visible
        }
      },
      {
        threshold: 0.01,
      },
    ));

    divRefs.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });

    return () => {
      divRefs.forEach((ref, index) => {
        if (ref.current) {
          observers[index].unobserve(ref.current);
        }
      });
    };
  }, []);

  const texts = [
    'Fill in our quick form.',
    'We\'ll schedule an introductory phone call.',
    'We\'ll take the time to listen and plan the next steps.',
  ];

  return (
    <div className="flex flex-col mt-4 text-black gap-3">
      {texts.map((text, index) => (
        <div
          key={`shake-div-${text}`} // Use a unique key
          ref={divRefs[index]}
          className={`flex flex-row content-center items-center gap-4 h-full justify-start p-3 w-full rounded-lg border bg-white ${isVisible[index] ? 'shake' : 'opacity-0'}`}
          style={{
            transitionDelay: `${index * 100}ms`, // Delay each shake effect
            opacity: isVisible[index] ? 1 : 0, // Control opacity for fade-in effect
            transition: 'opacity 0.9s ease-in-out', // Smooth opacity transition
          }}
        >
          <span className="material-symbols-outlined text-3xl leading-none text-orange-500 p-1">
            {index === 0 ? 'description' : <i className="fa-solid text-orange-500 text-2xl leading-none fa-phone-volume" />}
          </span>
          <h6>{text}</h6>
        </div>
      ))}
    </div>
  );
};

export default ShakeOnScroll;
