import { createSlice } from '@reduxjs/toolkit';
import { fetchBlogs, fetchBlogById } from '../service/appService';

export const initialState = {
  blogs: [], // For all blogs
  selectedBlog: null, // For a single blog
  loading: false, // API loading state
  error: null, // Error state
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      blogs: [],
      selectedBlog: null,
      loading: false,
      error: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      // Fetch all blogs
      .addCase(fetchBlogs.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(fetchBlogs.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        blogs: action.payload, // Replace blogs with fetched data
        error: null,
      }))
      .addCase(fetchBlogs.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload || action.error.message,
      }))

      // Fetch a blog by ID
      .addCase(fetchBlogById.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(fetchBlogById.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        selectedBlog: action.payload,
        error: null,
      }))
      .addCase(fetchBlogById.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload || action.error.message,
      }));
  },
});

export const { logout } = blogSlice.actions;

export default blogSlice.reducer;
