import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchBlogById } from '../../service/appService';
import CategoryBlogs from './CategoryBlogs';

function BlogDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog.blogs);
  const blog = blogs.find((b) => b.id === id);
  const { error } = useSelector((state) => state.blog.blogs);

  useEffect(() => {
    if (!blog) {
      dispatch(fetchBlogById(id));
    }
  }, [dispatch, id, blog]);

  if (error) throw error;

  const contentArray = Array.isArray(blog?.content)
    ? blog.content
    : blog?.content?.split('\n');

  // Get Previous and Next Blog
  const currentIndex = blogs.findIndex((b) => b.id === id);
  const prevBlog = blogs[currentIndex - 1];
  const nextBlog = blogs[currentIndex + 1];

  return (
    <div className="flex p-6 max-w-3xl mx-auto">
      {/* Blog Content */}
      <div className="card p-6 shadow-md rounded-lg bg-white hover:shadow-lg transition-shadow duration-300">
        <div className="img-container mb-4">
          <img
            src={blog?.image_url}
            alt={blog?.title}
            className="w-full h-48 object-cover rounded"
          />
        </div>
        <h2 className="font-bold text-xl text-gray-800">{blog?.title}</h2>
        <div className="text-sm text-gray-500">
          Category:
          {' '}
          <span className="text-gray-800 font-medium">{blog?.category}</span>
        </div>
        <div className="text-sm text-gray-600 mt-1">
          by
          {' '}
          {blog?.author_name || 'Unknown'}
          {' '}
          |
          {' '}
          {blog?.publish_date}
        </div>
        <div className="text-green-700 text-sm mt-2 mb-4">
          {contentArray.map((index) => (
            <p key={`${id}-${index}`} className="mb-4">{index}</p>
          ))}
        </div>
        {/* Navigation Links */}
        <div className="flex justify-between mt-8">
          {prevBlog && (
          <Link to={`/blog/${prevBlog.id}`} className="text-blue-500 hover:underline">
            Previous:
            {' '}
            {prevBlog.title}
          </Link>
          )}
          {nextBlog && (
          <Link to={`/blog/${nextBlog.id}`} className="text-blue-500 hover:underline">
            Next:
            {' '}
            {nextBlog.title}
          </Link>
          )}
        </div>
      </div>

      {/* Category Blogs and Recent Posts */}
      {blog?.category && <CategoryBlogs category={blog.category} blogs={blogs} />}
    </div>
  );
}

export default BlogDetail;
