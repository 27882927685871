import { configureStore } from '@reduxjs/toolkit';
import scheduleReducer from './scheduleReducer';
import blogReducer from './blogReducer';
import scheduleCallReducer from './scheduleCallReducer';

const store = configureStore({
  reducer: {
    schedule: scheduleReducer,
    blog: blogReducer,
    callSchedule: scheduleCallReducer,
  },
});

export default store;
