import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './v1/pages/Home';
import About from './v1/pages/About';
import Blog from './v1/pages/Blog';
import BlogDetail from './v1/pages/BlogDetail';
import HomeLayout from './v1/Component/Layouts/HomeLayout';
import ScheduleForm from './v1/pages/ScheduleForm';
import OurServices from './v1/pages/OurServices';
import Privacy from './v1/pages/Privacy';

function App() {
  useEffect(() => {
    window.scrollTo(1, 1);
  }, []);

  return (
    <Router>
      <div className="app">
        <div className="app-content h-screen">
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/schedule-your-appointment" element={<ScheduleForm />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/about-us/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
              <Route path="/our-services" element={<OurServices />} />
              <Route path="/privacy-policy" element={<Privacy />} />
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
