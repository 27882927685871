import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const appService = axios.create({
  baseURL: BASE_URL,
});

export const scheduledCall = createAsyncThunk(
  'user/scheduleCall',
  async (callSchedule, { rejectWithValue }) => {
    console.log('Booking appointment with data:', callSchedule);
    try {
      const response = await appService.post('api/schedule-a-call', callSchedule, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const bookedAppointment = createAsyncThunk(
  'user/scheduleBooked',
  async (subscriber, { rejectWithValue }) => {
    console.log('Booking appointment with data:', subscriber);
    try {
      const response = await appService.post('api/schedule-your-appointment', subscriber, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const fetchBlogs = createAsyncThunk(
  'blog/fetch',
  async (_, { rejectWithValue }) => {
    console.log('Fetching blogs...');
    try {
      const response = await appService.get('api/view-blogs');
      console.log('Blogs fetched successfully:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

export const fetchBlogById = createAsyncThunk(
  'selectedBlog/fetchById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appService.get(`/api/myblogs/${id}`);
      console.log('Blogs fetched now:', response.data);
      return response.data; // Fetch the blog data by ID
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);

// export const fetchBlogById = (id) => async (dispatch) => {
//   try {
//     const response = await appService.get(`/api/myblogs/${id}`);
//     return response.data;
//     dispatch({ type: 'FETCH_BLOG_SUCCESS', payload: data });
//   } catch (error) {
//     dispatch({ type: 'FETCH_BLOG_FAILURE', payload: error.message });
//   }
// };

export const selectBlogs = (state) => state.blog.blog;
export const selectedBlog = (state) => state.blog.blog;
export const selectLoading = (state) => state.blog.loading;
export const selectError = (state) => state.blog.error;

export default appService;
