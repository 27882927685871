import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '../Component/Button';
import { bookedAppointment } from '../../service/appService';
import Notification from '../Component/Notification';

function ScheduleForm() {
  const [subscriber, setSubscriber] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    company_name: '',
    company_size: '',
    appointment_text: '',
  });
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ message: '', type: 'success' });

  const handleSubscriber = (e) => {
    const {
      name,
      value,
      type,
      checked,
    } = e.target;
    if (type === 'checkbox') {
      setTermsAccepted(checked);
    } else {
      setSubscriber((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      alert('You must accept the terms and conditions.');
      return;
    }

    setLoading(true);
    try {
      const newSubscriber = await dispatch(bookedAppointment(subscriber)).unwrap();

      if (newSubscriber) {
        setSubscriber({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          company_name: '',
          company_size: '',
          appointment_text: '',
        });
        setNotification({ message: newSubscriber.message, type: 'success' });
        setTimeout(() => {
          setNotification({ message: '', type: 'success' });
          navigate('/');
        }, 7000);
      } else {
        setNotification({ message: newSubscriber.error.message, type: 'error' });
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setNotification({ message: error.message, type: 'error' });
      setTimeout(() => {
        setNotification({ message: '', type: '' });
      }, 7000); // Clear notification and navigate after 7 seconds
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="w-full h-full mt-4 p-3 md:p-16">
      <section className="mail-frm lg:flex lg:flex-row lg:gap-4 w-full">
        <div className="sec-form w-full">
          <Notification notification={notification} />
          <div className="flex flex-col">
            <h2 className="p-2">
              Submit an RFP
            </h2>
            <p className="p-2">
              Thank you for your interest in our services.
            </p>
            <p className="p-2">
              Please take a few moments to complete this form to clarify your request. We will review your information and get back to you.
            </p>
          </div>
          <div className="form border rounded p-3 mt-8">
            <form onSubmit={handleSubmit}>
              <fieldset className="flex flex-col mb-2 gap-3">
                <div className="form-st flex gap-3">
                  <div className="w-full">
                    <input
                      className="border rounded-lg border-gray-400 w-full p-3"
                      type="text"
                      name="first_name"
                      required
                      placeholder="First Name"
                      value={subscriber.first_name}
                      onChange={handleSubscriber}
                    />
                  </div>
                  <div className="w-full">
                    <input
                      className="border rounded-lg border-gray-400 w-full p-3"
                      type="text"
                      name="last_name"
                      required
                      placeholder="Last Name"
                      value={subscriber.last_name}
                      onChange={handleSubscriber}
                    />
                  </div>
                </div>
                <div className="form-st flex gap-3">
                  <div className="w-full">
                    <input
                      className="border rounded-lg border-gray-400 w-full p-3"
                      type="email"
                      name="email"
                      required
                      placeholder="Email"
                      inputMode="email"
                      value={subscriber.email}
                      onChange={handleSubscriber}
                    />
                  </div>
                  <div className="w-full">
                    <input
                      className="border rounded-lg border-gray-400 w-full p-3"
                      type="number"
                      name="phone_number"
                      required
                      placeholder="Phone Number"
                      value={subscriber.phone_number}
                      onChange={handleSubscriber}
                    />
                  </div>
                </div>
                <div className="form-st flex gap-3">
                  <div className="w-full">
                    <input
                      className="border rounded-lg border-gray-400 w-full p-3"
                      type="text"
                      name="company_name"
                      required
                      placeholder="Company"
                      value={subscriber.company_name}
                      onChange={handleSubscriber}
                    />
                  </div>
                  <div className="w-full">
                    <select
                      className="border rounded-lg border-gray-400 w-full p-3"
                      name="company_size"
                      required
                      aria-label="Company size"
                      value={subscriber.company_size}
                      onChange={handleSubscriber}
                    >
                      <option value="" hidden>Company size</option>
                      <option value="10">1-10</option>
                      <option value="20">11-20</option>
                      <option value="50">21-50</option>
                      <option value="100">51-100</option>
                      <option value="500">101-500</option>
                      <option value="500+">500+</option>
                    </select>
                  </div>
                </div>
                <div className="w-full">
                  <textarea
                    className="w-full h-48 border border-gray-400 rounded-lg p-2 resize-none"
                    name="appointment_text"
                    required
                    placeholder="How can we help you?"
                    value={subscriber.appointment_text}
                    onChange={handleSubscriber}
                  />
                </div>
                <div className="w-full flex items-center">
                  <input
                    type="checkbox"
                    name="terms"
                    checked={termsAccepted}
                    onChange={handleSubscriber}
                  />
                  <p className="ml-2">
                    I accept the terms and conditions
                  </p>
                </div>
              </fieldset>
              <div className="flex justify-end mt-4">
                <Button
                  type="submit"
                  loading={loading}
                  text="SCHEDULE NOW"
                  ariaLabel="Submit button"
                  className="justify-start"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="mail-img hidden md:hidden lg:flex lg:object-cover bg-black lg:w-full lg:min-h-full">
          <img src="https://media.istockphoto.com/id/1646176242/photo/contact-us-on-a-grey-background-with-envelopes-on-the-speech-bubble.jpg?s=612x612&w=0&k=20&c=Fg1-891VdcYa5BKXSNR53V9kVGVCNZfPkEsbRoUkhhE=" className="w-full lg:min-h-full object-cover opacity-60 " alt="" />
        </div>
      </section>
    </main>
  );
}

export default ScheduleForm;
