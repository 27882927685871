import React from 'react';

function Privacy() {
  return (
    <div className="p-4">
      <article className="po-we bg-gray-200">
        <div className="x-content p-7">
          <div className="x-text flex flex-col p-content gap-6 text-slate-600">
            <h1 className="font-semibold text-3xl">Oval Professional Services Privacy Policy</h1>
            <h3 className="text-2xl">Our Commitment to Privacy</h3>
            <p>
              Your privacy is important to us. To better protect your privacy, we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. This policy applies to Oval Professional Services, Oval Professional Services Consulting, LLC, and their websites. To make this notice easy to find, we make it available on our homepage and at every point where personally identifiable information may be requested.
            </p>
            <h4>The Information We Collect</h4>
            <p>This notice applies to all information collected or submitted on the website. On some pages, you can make requests and register to receive materials. The types of personal information collected at these pages are:</p>
            <ul className="list-disc ml-5 px-4">
              <li>Name</li>
              <li>Address</li>
              <li>Email address</li>
              <li>Employer</li>
              <li>Job Title</li>
              <li>Phone number</li>
            </ul>
            <h4 className="text-2xl">The Way We Use Information</h4>
            <p>
              We use the information you provide about yourself when placing an order only to complete that request for information. We do not share this information with outside parties except to the extent necessary to complete that process.
            </p>
            <p>
              We use return email addresses to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties.
            </p>
            <p>
              You can register with our website if you would like to receive information about our services. Information you submit on our website will not be used for this purpose unless you fill out the applicable registration form.
            </p>
            <p>
              We use non-identifying and aggregate information to better design our website but will not disclose anything that could be used to identify those individuals.
            </p>
            <p>Oval Professional Services does not sell, rent, or lease its customer lists to third parties.</p>
            <p>
              Oval Professional Services may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Oval Professional Services, and they are required to maintain the confidentiality of your information.
            </p>
            <p>
              Finally, we never use or share the personally identifiable information provided to us online in ways unrelated to the ones described above without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses.
            </p>
            <h4 className="text-2xl">Tracking User Behavior</h4>
            <p>
              Oval Professional Services may keep track of the websites and pages our users visit within our website to determine what solutions and services are the most popular. This data is used to deliver customized content and advertising within Oval Professional Services to customers whose behavior indicates that they are interested in a particular subject area.
            </p>
            <h4>Automatically Collected Information</h4>
            <p>
              Information about your computer hardware and software may be automatically collected by Oval Professional Services. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of our services, to maintain quality, and to provide general statistics regarding the use of the Oval Professional Services website.
            </p>
            <h4>Our Commitment to Data Security</h4>
            <p>
              To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
            </p>
            <h4>How You Can Access or Correct Your Information</h4>
            <p>
              You can inquire about all your personally identifiable information that we collect online and maintain by contacting us. We use this procedure to better safeguard your information.
            </p>
            <p>
              You can correct factual errors in your personally identifiable information by sending us a request that credibly shows the error. To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access or making corrections.
            </p>
            <h4 className="text-2xl">Our Data Controller</h4>
            <p>
              Oval Professional Services Consulting, LLC handles Personal Data as both a Processor and a Controller as defined in the General Data Protection Regulation (GDPR) approved on April 27th, 2016, and enacted on May 25th, 2018. This data is stored exclusively in secure hosting facilities in accordance with the requirements set forth by GDPR.
            </p>
            <h4 className="text-2xl">International Privacy Laws</h4>
            <p>
              If you are visiting the website from outside of the U.S.A., please be aware that you are sending personal information to the U.S.A. Depending on the type of information and how it is stored by us, that information may be transferred within the U.S.A. or to other countries outside of your country of residence. While these countries, including the U.S.A., may not have data protection laws as protective as those in your country of residence, our collection, storage, and use of your personal information will at all times be governed by this Privacy Policy.
            </p>
            <p>
              For personal information we receive from the EEA, Oval Professional Services Consulting, LLC has certified its compliance with GDPR regarding the collection, use, and storage of personal information from European Union countries.
            </p>
            <h4>How to Access, Correct, or Delete Your Information</h4>
            <p>
              You can contact us to inquire about your personally identifiable information that we collect online and maintain. We use this procedure to better safeguard your information.
            </p>
            <p>
              You can correct factual errors in your personally identifiable information by sending us a request that credibly shows the error(s).
            </p>
            <p>
              You can inquire about deleting the information that Oval Professional Services has about you by contacting us.
            </p>
            <p>
              To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access or making corrections.
            </p>
            <hr />
            <p><strong>If you have any questions, please contact us at:</strong></p>
            <p>
              info@OvalProServices.com
              {' '}
              <br />
              <strong>Oval Professional Services Consulting, LLC</strong>
              <br />
              Grace Avenue Suite 123
              {' '}
              <br />
              Beltimoore US.
            </p>
            <p>(240) 468-7794</p>
          </div>
        </div>
      </article>
    </div>
  );
}

export default Privacy;
