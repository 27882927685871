import React from 'react';
import PropTypes from 'prop-types';

const Notification = ({ notification }) => {
  if (!notification.message) return null;

  // Set background color based on the notification type
  const backgroundColor = notification.type === 'success' ? 'bg-green-400' : 'bg-red-400';

  return (
    <div className={`notification fixed top-20 left-2/4 p-2 w-4/5 text-center z-50 text-white ${backgroundColor}`}>
      {notification.message}
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
  }).isRequired,
};

export default Notification;
