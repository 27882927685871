import React from 'react';
import { Link } from 'react-router-dom';
import ovalLogo from '../Assets/Images/oval-pro-logo.jpeg';

export default function Footer() {
  const industries = [
    'Strategic IT planning',
    'Healthcare IT implementation',
    'Cloud computing',
    'Cybersecurity',
    'Interoperability',
    'Health data analytics',
  ];

  return (
    <footer className="bg-slate-500 text-white text-sm mt-24">
      <section className="specialization p-4 flex flex-col items-center md:flex-row w-full">
        <div className="w-full">
          <h2>Industries</h2>
          <div className="flex flex-row gap-4 mt-3">
            <ul className="flex flex-col w-full gap-2">
              {industries.slice(0, 3).map((industry) => (
                <li key={industry} className="flex items-baseline gap-2">
                  <i className="fa-solid fa-angle-right" aria-hidden="true" />
                  {industry}
                </li>
              ))}
            </ul>
            <ul className="flex flex-col w-full gap-2">
              {industries.slice(3).map((industry) => (
                <li key={industry} className="flex items-baseline gap-2">
                  <i className="fa-solid fa-angle-right" aria-hidden="true" />
                  {industry}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="b-footer flex flex-row w-2/3 gap-4 items-center justify-center mt-2">
          <img src={ovalLogo} alt="oval logo" className="w-10 h-10 rounded" />
          <div className="flex flex-col md:flex-row w-full flex-1 gap-3">
            <address className="w-full">
              <p>Grace Avenue Suite 123</p>
              <p>Beltimoore US.</p>
            </address>
          </div>
        </div>
        <div className="flex flex-col w-full text-center justify-center items-center gap-1 mt-3">
          <a href="tel:+012404687794" className="contact flex items-center gap-3">
            <div className="tel flex items-center">
              <span>
                <i className="fa-solid fa-headset text-orange-400" aria-hidden="true" />
              </span>
            </div>
            <span className="tel">(240) 468-7794</span>
          </a>
          <a href="mailto:info@ovalproservices.com?subject=Inquiry&body=Hello," className="contact flex items-center justify-center text-center gap-3">
            <div className="tel flex items-center">
              <span>
                <i className="fa-solid fa-envelope text-orange-400" aria-hidden="true" />
              </span>
            </div>
            <span className="mail">info@ovalproservices.com</span>
          </a>
        </div>
      </section>
      <section>
        <div className="flex flex-col items-center justify-center mb-4 md:flex-row">
          <div className="flex flex-row gap-2">
            ©
            <p className="text-gray-400">2024</p>
            Oval Pro Services
          </div>
          <span>
            {' '}
            All Right Reserved
            {' '}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
        </div>
      </section>
    </footer>
  );
}
