import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../pages/Header';
import Footer from '../Footer';

function HomeLayout() {
  const linkCategory = [
    { category: 'Home', to: '/' },
    {
      category: 'About Us',
      to: '/about-us',
      hasDropdown: true,
      subItems: [
        { category: 'Who We Are', to: '/about-us' },
        { category: 'Blog', to: '/about-us/blog' },
      ],
    },
    { category: 'Our Service', to: '/our-services' },
    { category: 'Contact Us', to: '/schedule-your-appointment' },
  ];

  return (
    <>
      <div>
        <Header linkCategory={linkCategory} />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default HomeLayout;
