import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Link } from 'react-router-dom';

function CategoryBlogs({ blogs }) {
  const [searchCategory, setSearchCategory] = useState('');
  const recentPosts = blogs;

  // Filter recent posts by category based on the search input
  const filteredRecentPosts = recentPosts.filter((post) => post.category.toLowerCase().includes(searchCategory.toLowerCase()));

  return (
    <div className="mt-8">
      {/* Search Input */}
      <div className="mb-6">
        <input
          type="text"
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
          placeholder="Search recent posts by category..."
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      {/* Recent Posts */}
      <div className="mt-8">
        <h3 className="font-bold text-lg">Recent Posts</h3>
        <div className="grid grid-cols-1 gap-4 mt-4">
          {filteredRecentPosts.length > 0 ? (
            filteredRecentPosts.map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.id}`}
                className="flex flex-row gap-2 bg-gray-100 rounded hover:bg-gray-200"
              >
                <img
                  src={post.image_url || '/path/to/default-image.jpg'}
                  alt={post.title}
                  className="w-18 h-24 object-cover rounded"
                />
                <div className="flex flex-col">
                  <h4 className="font-medium">{post.title}</h4>
                  <p className="text-sm text-gray-500">{post.category}</p>
                </div>
              </Link>
            ))
          ) : (
            <p className="text-gray-600">
              No recent posts found for the entered category.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

// Add Prop Validation
CategoryBlogs.propTypes = {
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired, // Assuming `id` is a number
      title: PropTypes.string.isRequired,
      image_url: PropTypes.string,
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CategoryBlogs;
