// StarRating.js
import React from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ rating }) => {
  const totalStars = 5;

  return (
    <div className="star-rating">
      {Array.from({ length: totalStars }, (_, index) => (
        <span key={index} className="star text-2xl rounded text-yellow-300">
          {index < rating ? '★' : '☆'}
        </span>
      ))}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
