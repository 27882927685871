import { createSlice } from '@reduxjs/toolkit';
import { scheduledCall } from '../service/appService'; // Make sure to import the necessary actions

export const initialState = {
  callSchedule: null,
  loading: false,
  error: null,
  scheduleCall: false,
};

const scheduleCallSlice = createSlice({
  name: 'callSchedule',
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      callSchedule: null,
    }),
    resetscheduleCall: (state) => ({
      ...state,
      scheduleCall: false,
    }),
  },
  extraReducers: (builder) => {
    // Handle scheduledCall actions
    builder
      .addCase(scheduledCall.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(scheduledCall.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        scheduleCall: action.payload,
        error: null,
      }))
      .addCase(scheduledCall.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload || action.error.message,
      }));
  },
});

export const { logout, resetscheduleCall } = scheduleCallSlice.actions;

export default scheduleCallSlice.reducer;
