import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import expertise from '../Assets/Images/our expertise.jpg';

function OurServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex flex-col about-us-container w-full mt-0">
      <section className="flex flex-col items-center justify-center bg-center h-72 w-full overflow-visible bg-no-repeat bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/conference.jpg')] bg-gray-700 bg-blend-multiply">
        <div className="flex flex-col justify-center items-center w-full h-full bg-slate-500 bg-opacity-50">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl border rounded-md p-2">Our Services</h1>
        </div>
      </section>

      <section className="p-6 lg:px-44 ">
        <div className="mt-12">
          <h2 className="w-full bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-2 text-2xl font-bold text-center text-blue-500">
            IT Consulting Capability Statement
          </h2>
          <div className="break-all text-left mt-5 ">
            <strong className="text-xl font-semibold mr-2 text-orange-500">Our Mission:</strong>
            To empower businesses with innovative IT solutions that drive growth, efficiency, and success. This speaks more of who we are in delivering quility service to our customers
            <p>We specialize in providing system optimization, professional IT support staff, healthcare application support, implementation management, and change management services.</p>
          </div>
          <img
            src="https://media.istockphoto.com/id/488536442/photo/business-man-in-suit-with-cityscape-montage.jpg?s=612x612&w=0&k=20&c=QphgzTV2X-xBJtJHTY-xiWaBvxqCETciU0lCuQU0xF4="
            alt="Our Mission"
            className="w-full h-48 object-cover rounded-lg mt-6 md:h-60 lg:h-72"
          />
        </div>

        <div className="ft-ca mt-4 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 md:p-12">
          <h2 className="text-2xl font-semibold text-center mb-7 text-blue-500">Our Expertise</h2>
          <div className="exp-li flex flex-col md:flex-row lg:flex-row gap-5 w-full">
            <div className="w-full lg:inline-flex order-1">
              <img
                src={expertise}
                alt="Our Expertise"
                className="w-fit h-full object-cover lg:object-cover rounded-lg"
              />
            </div>
            <div className="li-exp w-full flex lg:flex-initial">
              <ul className="list-disc text-balance ml-2 gap-1 mb-4 w-full">
                <li>
                  <strong>IT Strategy and Planning:</strong>
                  <br />
                  {' '}
                  Developing tailored IT strategies aligned with business objectives.
                </li>
                <li>
                  <strong>Infrastructure Management:</strong>
                  <br />
                  {' '}
                  Designing, implementing, and maintaining robust IT infrastructure.
                </li>
                <li>
                  <strong>Cloud Solutions:</strong>
                  <br />
                  {' '}
                  Leveraging cloud-based technologies for scalability and flexibility.
                </li>
                <li>
                  <strong>Cybersecurity:</strong>
                  <br />
                  {' '}
                  Protecting sensitive patient data and mitigating cyber threats.
                </li>
                <li>
                  <strong>Application Development:</strong>
                  {' '}
                  Creating custom software solutions to meet specific needs.
                </li>
                <li>
                  <strong>IT Support and Maintenance:</strong>
                  <br />
                  {' '}
                  Providing comprehensive IT support and maintenance services.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="se-ca mt-4 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 md:p-12">
          <h2 className="text-xl font-semibold mt-6 mb-7 text-center text-blue-500">Our Approach</h2>
          <div className="flex flex-col md:flex-row lg:flex-row gap-5 w-full">
            <div className="w-full lg:inline-flex order-first object-cover">
              <img
                src="https://cdn.pixabay.com/photo/2020/05/26/14/25/banner-5223286_640.jpg"
                alt="Our Expertise"
                className="w-full h-full object-cover rounded-lg md:h-60"
              />
            </div>
            <div className="li-exp w-full flex lg:flex-initial">
              <ul className="list-disc ml-6 mb-4 w-full">
                <li>
                  <strong>Collaborative partnerships:</strong>
                  <br />
                  {' '}
                  Working closely with clients to understand their unique requirements.
                </li>
                <li>
                  <strong>Customized solutions:</strong>
                  <br />
                  {' '}
                  Tailoring IT solutions to fit specific business needs.
                </li>
                <li>
                  <strong>Proven methodologies:</strong>
                  <br />
                  {' '}
                  Employing industry-best practices and frameworks.
                </li>
                <li>
                  <strong>Results-driven focus:</strong>
                  <br />
                  {' '}
                  Delivering tangible benefits and measurable outcomes.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="th-ca mt-4 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 md:p-12">
          <h2 className="text-xl font-semibold mt-6 mb-7 text-center text-blue-500">Our Benefits</h2>
          <ul className="list-disc ml-6 mb-4 mt-4">
            <li>
              <strong>Improved Efficiency:</strong>
              {' '}
              Streamlined operations and reduced costs.
            </li>
            <li>
              <strong>Enhanced Security:</strong>
              {' '}
              Protected data and minimized risks.
            </li>
            <li>
              <strong>Increased Productivity:</strong>
              {' '}
              Empowered employees with the right tools and technology.
            </li>
            <div className="flex flex-col md:flex-row lg:flex-row gap-5 w-full mt-4">
              <div className="w-full lg:inline-flex order-first object-cover">
                <img
                  src="https://cdn.pixabay.com/photo/2018/03/10/12/34/economy-3213967_640.jpg"
                  alt="Our Benefits"
                  className="w-full h-full object-cover rounded-lg md:h-60 lg:h-72"
                />
              </div>
              <div className="w-full flex flex-col mb-4 mt-2 lg:ml-3 lg:flex-initial ">
                <li>
                  <strong>Scalability:</strong>
                  {' '}
                  Adaptable solutions to support business growth.
                </li>
                <li>
                  <strong>Competitive Advantage:</strong>
                  {' '}
                  Gaining a competitive edge through innovative IT solutions.
                </li>
              </div>
            </div>
          </ul>
        </div>
        <fiv className="fl-ca">
          <h2 className="text-xl font-semibold mt-6 text-center">
            Let&apos;s
            {' '}
            <NavLink className="underline text-blue-400" to="/schedule-your-appointment">partner</NavLink>
            {' '}
            to transform your business.
          </h2>
        </fiv>
      </section>
    </main>
  );
}

export default OurServices;
