import PropTypes from 'prop-types';
import React from 'react';

function Button({
  text,
  onClick,
  ariaLabel,
  loading = false,
}) {
  return (
    <button
      type="submit" // Ensure this is specified correctly
      onClick={onClick}
      aria-label={ariaLabel}
      className={`bg-slate-500 text-b text-white font-medium p-3 rounded flex items-center justify-center ${loading ? 'cursor-not-allowed' : ''}`}
      disabled={loading} // Disable the button when loading
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0h-2a6 6 0 00-12 0h-2z" />
        </svg>
      ) : null}
      {loading ? 'Loading...' : text}
    </button>
  );
}

// Define PropTypes for all props
Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

// Button.defaultProps = {
//   loading: false, // Default loading to false
// };

export default Button;
