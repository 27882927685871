import React from 'react';

const AboutUs = () => (
  <main className="flex flex-col about-us-container w-full mt-0">
    <section className="flex flex-col items-center justify-center bg-center h-72 w-full overflow-visible bg-no-repeat bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/conference.jpg')] bg-gray-700 bg-blend-multiply">
      <div className="flex flex-col justify-center items-center w-full h-full bg-slate-500 bg-opacity-50">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl border rounded-md p-2">About Us</h1>
      </div>
    </section>

    <section className="p-6 text-wrap text-justify lg:p-24">
      <div className="wh-e-re md:p-4 md:text-2xl">
        <div className="abt-header text-center">
          <strong className=" text-slate-700 text-center font-bold text-wrap text-3.5sm leading-2-custom md:text-4xl">Why Our Clients Work with Us</strong>
        </div>
        <div className="ab-bd-ft mt-8">
          <p className="ab-bd">
            At Oval Professional Services, we pride ourselves on over 20 years of unparalleled expertise in the IT industry. We specialize in providing system optimization, professional IT support staff, healthcare application support, implementation management, and change management services.
          </p>
        </div>
        <div className="ab-bd-se mt-6 ">
          Our team is comprised of seasoned consultants with extensive experience across IT, healthcare, and the energy sectors. We&apos;ve consistently delivered innovative solutions that drive efficiency and foster growth. Our commitment to excellence ensures that our clients receive tailored strategies and seamless integration, empowering them to navigate the complexities of their industries with confidence.
        </div>
        <div className="ab-bd-ve mt-6 md:flex md:gap-3">
          <div className="img-abt-a md:order-last md:w-full">
            <img src="https://media.istockphoto.com/id/2148900871/photo/team-portrait-and-meeting-at-office-with-diversity-trust-and-support-with-public-relations.jpg?s=612x612&w=0&k=20&c=h7778woFYuBvl4Pwu2TfZLZ45ZEiu-F4GPb5K2UuDFU=" alt="abt us" className="mb-3 rounded-lg" />
          </div>
          <p className="w-full lg:p-4 text-balance">Oval Pro Services specializes in partnering with small, medium, and fast-growing organizations in our envrons and beyond. Our deep understanding of the local business landscape enables us to provide tailored IT solutions that align with the specific needs and opportunities of our clients.</p>
        </div>
        <div className="ab-bd-tr mt-6 p-3">
          <p>
            At Oval Professional Services, we don&apos;t just offer services—we build lasting partnerships that pave the way for sustained success.
          </p>
        </div>
      </div>
      <div className="cl-ab mt-6">
        <h2 className="font-bold text-slate-700 mb-3  md:text-4xl md:mb-4">Client Comes First</h2>
        <div className="abt-b-s md:flex md:gap-3 md:text-2xl">
          <div className="img-abt-b md:order-first md:w-full">
            <img src="https://media.istockphoto.com/id/1495097491/photo/business-people-networking-in-the-modern-space.jpg?s=612x612&w=0&k=20&c=oMSeSiK6yD-i_DDRouSiqbiRYMbz9Z1gnepiov9wo2A=" alt="" className="mb-3 rounded-lg" />
          </div>
          <article className="w-full  lg:p-4 text-balance">
            <p>At Oval Pro Services, We focus on cultivating long-term, healthy relationships with our clients because we believe that trust, reliability, and prioritizing their best interests are essential for success in business. Our commitment to customer-centric values informs every aspect of our work, guiding us to deliver the best possible outcomes for our clients.</p>
          </article>
        </div>
      </div>
      <div className="cl-ab mt-6">
        <h2 className="font-bold text-slate-700 mb-3  md:text-4xl md:mb-4">Technical Expertise</h2>
        <div className="abt-b-s md:flex md:gap-3 md:text-2xl">
          <div className="img-ab object-cover md:order-last md:w-full">
            <img src="https://media.istockphoto.com/id/1408255024/photo/developers-discussing-programming-code.jpg?s=612x612&w=0&k=20&c=W8HQvPEwDMzk5Vw9bA0EoJIhG-ETDiW0wed4GB8lReA=" alt="" className="mb-3 rounded-lg" />
          </div>
          <article className="w-full lg:p-4 text-balance">
            <p>We as technology experts believe and recognize that our true value lies in our customer-centric approach. We go beyond just technical knowledge to fully understand and address our clients&#39; needs and goals, delivering IT solutions that are tailored specifically to their requirements.</p>
          </article>
        </div>
      </div>
      <div className="cl-ab mt-6">
        <h2 className="font-bold text-slate-700 mb-3 text-wrap">Proactive Problem Solving</h2>
        <div className="abt-b-s md:flex md:gap-3 md:text-2xl">
          <div className="img-ab object-cover md:order-last md:w-full">
            <img src="https://media.istockphoto.com/id/2148063382/photo/data-cube-abstract-cubic-structure-symbol-of-technology-research-and-digital-architecture.jpg?s=612x612&w=0&k=20&c=K5jNEThHFQ6vehHTmWinzdzELyU3AwQ3HSfM6MR34v8=" alt="" className="mb-3 rounded-lg object-cover" />
          </div>
          <article className="w-full lg:p-4 text-balance">
            <p>Unlike many managed IT providers, we don&#39;t merely respond to issues as they come up. We adopt a proactive strategy, identifying and resolving potential problems before they impact our clients&#39; businesses. By anticipating challenges and charting a clear path forward, we enable our clients to avoid disruptions and effectively achieve their growth goals.</p>
          </article>
        </div>
      </div>

      <h2 className="text-xl font-semibold mt-6 text-center border border-separate p-2 rounded-md">Let&apos;s partner to transform your organization.</h2>
    </section>
  </main>
);

export default AboutUs;
