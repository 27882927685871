import { createSlice } from '@reduxjs/toolkit';
import { bookedAppointment } from '../service/appService'; // Make sure to import the necessary actions

export const initialState = {
  schedule: null,
  loading: false,
  error: null,
  scheduleBooked: false,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      schedule: null,
    }),
    resetscheduleBooked: (state) => ({
      ...state,
      scheduleBooked: false,
    }),
  },
  extraReducers: (builder) => {
    // Handle bookedAppointment actions
    builder
      .addCase(bookedAppointment.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(bookedAppointment.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        scheduleBooked: action.payload,
        error: null,
      }))
      .addCase(bookedAppointment.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload || action.error.message,
      }));
  },
});

export const { logout, resetscheduleBooked } = scheduleSlice.actions;

export default scheduleSlice.reducer;
