import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../App.css';
import logo from '../Assets/Images/oval-pro-logo.jpeg';

function Header({ linkCategory }) {
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState({});

  const handleItemClick = (category) => {
    setIsDropdownOpen((prev) => {
      const isOpen = prev[category.category];
      // Close all dropdowns except the one clicked
      const newState = {};
      Object.keys(prev).forEach((key) => {
        // Close all
        newState[key] = false;
      });
      // Toggle clicked dropdown
      newState[category.category] = !isOpen;

      return newState;
    });
    // Do not close side menu when clicking a category with a dropdown
    if (!category.hasDropdown) {
      setIsSideMenu(false);
    }
  };

  const handleCloseMenu = () => {
    setIsDropdownOpen({});
    setIsSideMenu(false);
  };

  return (
    <header className="ht-content sticky top-0 bg-white shadow z-10">
      <div className="h-top flex flex-col items-center justify-center md:flex-row lg:justify-end h-full px-4 py-3 bg-slate-500 text-white text-xs gap-1">
        <div className="flex flex-row items-center justify-center text-center gap-1">
          <a href="tel:+2348131146850" className="contact flex items-center">
            <div className="tel flex items-center">
              <span>
                <i className="fa-solid fa-headset text-orange-400" />
              </span>
              <span className="ml-2 text-white">Contact Us: </span>
            </div>
            <span className="tel">(240) 468-7794</span>
          </a>
          |
        </div>
        <a href="tel:+12404687794" className="contact flex items-center">
          <span className="tel flex items-center">
            <i className="fa-solid fa-phone text-orange-400" />
            <span className="ml-2 text-white">Sales Contact: </span>
          </span>
          <span className="tel">(240) 468-7794</span>
        </a>
      </div>
      <div className="logo flex md:w-full lg:h-16 lg:px-7 justify-between w-full bg-orange-400 p-3 md:justify-between bg-gradient-to-r from-orange-300 to-blue-400">
        <div className="logo-container">
          <Link to="/" className=" flex h-auto w-auto object-cover">
            <img src={logo} alt="Logo" className="" />
          </Link>
        </div>
        <div className="h-nav">
          <div
            className="hamburge flex border p-3 md:hidden lg:hidden flex-none"
            role="button"
            aria-label="Toggle menu"
            onClick={() => setIsSideMenu(!isSideMenu)}
            tabIndex={0}
            onKeyPress={() => setIsSideMenu(!isSideMenu)}
          >
            <i className={`fa-solid fa-bars text-white ${isSideMenu ? 'active' : ''}`} />
          </div>
          <div className={`side-nav lg:hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 transition-transform ${isSideMenu ? 'translate-x-0' : '-translate-x-full'}`}>
            <nav className="side-bar w-72 absolute top-0 left-0 bottom-0 z-10 bg-white">
              <span
                role="button"
                aria-label="Close menu"
                className="close"
                onClick={handleCloseMenu}
                tabIndex={0}
                onKeyPress={handleCloseMenu}
              >
                <i className="flex justify-end p-2 fa-solid fa-x fa-xmark text-red-600" />
              </span>
              <ul className="side-bar-items flex flex-col p-2 gap-2">
                {linkCategory.map((category) => (
                  <li
                    key={category.category}
                    className="side_menu_item p-0  px-2 py-1 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    role="menuitem"
                    onClick={() => handleItemClick(category)}
                    tabIndex={0}
                    onKeyPress={() => handleItemClick(category)}
                  >
                    <Link to={category.to} className="flex justify-between w-full gap-2">
                      {category.category}
                      {category.hasDropdown && (
                        <i
                          className={` flex justify-center items-center w-6 h-6 fa-solid fa-angle-right ml-5 ${isDropdownOpen[category.category] ? 'rotate-90' : ''}`}
                        />
                      )}
                    </Link>
                    {category.hasDropdown && isDropdownOpen[category.category] && (
                      <ul className="dropdown-menu flex flex-col gap-2 relative mt-2 no-underline">
                        {category.subItems.map((subItem) => (
                          <li key={subItem.category} className=" flex dropdown-items w-full border p-1">
                            <Link to={subItem.to} onClick={handleCloseMenu} className="flex w-full">
                              {subItem.category}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="flex flex-col mr-8">
            {/* Desktop nav */}
            <nav className="side-bar hidden md:flex lg:flex w-full bottom-0 z-10">
              <ul className="side-bar-items flex w-full gap-2 flex-row">
                {linkCategory.map((category) => (
                  <li
                    key={category.category}
                    className=" px-3 py-1 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900 gap-2"
                    role="menuitem"
                    onClick={() => handleItemClick(category)}
                    tabIndex={0}
                    onKeyPress={() => handleItemClick(category)}
                  >
                    <Link to={category.to} className="flex items-center font-medium p-0 justify-between w-full text-white text-center hover:text-cyan-300 hover:underline">
                      {category.category}
                      {category.hasDropdown && (
                        <i className={`fa-solid fa-angle-right ml-3 no-underline hover:${isDropdownOpen[category.category] ? 'rotate-90' : ''}`} />
                      )}
                    </Link>
                    <div className="sub-st flex items-center justify-center gap-2 relative col">
                      {category.hasDropdown && isDropdownOpen[category.category] && (
                        <ul className="dropdown-menu flex flex-col top-3 absolute bg-slate-100 border w-32 px-1 py-0 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900">
                          {category.subItems.map((subItem) => (
                            <li key={subItem.category} className="dropdown-item w-full">
                              <Link to={subItem.to} onClick={() => { setIsDropdownOpen({}); }} className=" flex h-full w-full">
                                {subItem.category}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  linkCategory: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      hasDropdown: PropTypes.bool,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default Header;
