import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../../service/appService';

function Blog() {
  const dispatch = useDispatch();
  const { blogs, error } = useSelector((state) => state.blog);
  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  if (error) {
    return <div className="text-red-500">{error.message}</div>;
  }

  if (!Array.isArray(blogs) || blogs.length === 0) {
    return <div className="text-gray-700">No blogs available.</div>;
  }

  return (
    <main className="flex flex-col">
      <section className="flex flex-col items-center justify-center bg-center h-72 w-full overflow-visible bg-no-repeat bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/conference.jpg')] bg-gray-700 bg-blend-multiply">
        <div className="flex flex-col justify-center items-center w-full h-full bg-slate-500 bg-opacity-50">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl border rounded-md p-2">Blog</h1>
        </div>
      </section>
      <section className="grid items-center justify-center md:grid-cols-3 gap-4 p-4">
        {blogs.map((blog) => {
          // Ensure content is a string for preview; join array if necessary
          const previewContent = Array.isArray(blog.content)
            ? blog.content.join(' ')
            : blog.content; // Default to an empty string if content is missing

          return (
            <div key={blog.id} className="card p-2 h-full justify-start shadow-md rounded-lg bg-white hover:shadow-lg transition-shadow duration-300">
              <div className="img-container mb-4 w-full">
                <img
                  src={blog.image_url || '/path/to/default-image.jpg'}
                  alt={blog.title}
                  className="w-full h-48 object-cover rounded"
                />
              </div>
              <h2 className="p-2 font-bold text-xl text-gray-800">{blog.title}</h2>
              <div className="flex flex-row items-center justify-start gap-4">
                <div className="p-2 text-sm text-gray-500">
                  Category:
                  {' '}
                  <span className="text-gray-800 font-medium">{blog.category}</span>
                </div>
                <div className="text-sm text-gray-600">
                  Tags:
                  {' '}
                  {Array.isArray(blog.tags) ? blog.tags.join(', ') : 'No tags'}
                </div>
              </div>
              <div className="p-2 text-sm text-gray-600 mt-1">
                by
                {' '}
                :
                {blog.author_name}
                {' '}
                |
                {' '}
                {blog.publish_date}
              </div>
              <div className="p-2 text-green-700 text-sm text-justify mt-2 mb-4">
                {previewContent.substring(0, 200)}
                ...
              </div>
              <Link
                to={`/blog/${blog.id}`}
                className="text-blue-600 hover:underline"
              >
                Read More...
              </Link>
            </div>
          );
        })}
      </section>
    </main>
  );
}

export default Blog;
